//====================================================
//  Function: Validate forms
//====================================================

function componentValidateForms(){
	
	// Basket submit
	if ($( '#form-basket' ).length){
		var formBasket = $( '#form-basket' );

		formBasket.parsley().on( 'form:submit' , function(){
			formatFormResult(formBasket);

			return false;
		});
	}

	// Login submit
	if ($( '#form-login' ).length){
		var formLogin = $( '#form-login' );

		formLogin.parsley().on( 'form:submit' , function(){
			formatFormResult(formLogin);

			return false;
		});
	}
	

	function formatFormResult(form){

		var unindexed_array = form.serializeArray();
		var indexed_array = {};

		$.map(unindexed_array, function(n, i){
			indexed_array[n['name']] = n['value'];
		});
		console.log("Format 1: ");
		console.log(form.serialize());
		console.log('\n');
		
		console.log("Format 2: ");
		console.log(form.serializeArray());
		console.log('\n');

		console.log("Format 3: ");
		console.log(indexed_array);
		console.log('\n');

		alert("Check console");
	}
}
