// --- GLOBAL VARIABLES --- //
var $window = $(window);
var $document = $(document);
var $header = $('.header');
var bpSmall = 771;
var bpMedium = 992;
var bpLarge = 1200;
var classActive = 'is-active';
var classDisabled = 'is-disabled';
var classLast = 'is-last';
var classHasError = 'has-error';
var classLoading = 'is-loading';
var classOpened = 'is-opened';
var classSelected = 'is-selected';
var classCollapse = 'is-collapse';
var classAnimated = 'is-animated';
var $this = null;
var $containers = $('html,body');

// --- COMPONENTS, PARTS --- //
$(document).ready(function() {
	// ---  COMPONENTS --- //
	// Quantity Input
	componentModal();

	// Quantity Input
	componentQuantityInput();

	// Validate forms
	componentValidateForms();

	// Textarea Resize
	componentTextareaResize();
	
	// Gallery
	componentGallery();
	
	// Slider
	componentSlider();
	
	// Navigation
	componentFullscreenNavigation();

	// Scroll to
	partScrollTo();
});


// --- LOAD EVENT --- //
$(window).bind('load', function() {
	// Dots
	partDots();

	// Page Loader
	$('body').addClass(classActive);

});
